@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.App {
  text-align: center;
  background-color: rgb(227, 227, 227);
  font-family: 'Montserrat', sans-serif;
  /* background-color: rgb(196,189,139); */
}



.container{
  
  top:0;
  width: 100%;
  height: 100vh;
}

.div-header{
  background-image: url('./assets/bg260.jpg');
 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  /* background-attachment: fixed; */
  /* background-color: rgb(143,170,179); */
}

.div-img{
  width: 100vw;
}

.nav-bar{

  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: 5vh; */
}

.nav-links{
  position: fixed;
  top: 0;
  right: 20px;
  display: flex;
  /* margin-right: 20px; */
}
.nav-name{
  position: fixed;
  top: 0;
  margin-left: 20px;
}

.nav-links a{
  color:black;
  margin: 0 5px 0 5px;
}

.name{
  font-size: 100px;
  font-weight: bolder;
  margin-bottom: -30px;
}

#resume-icon{
  border-radius: 100%;
}



.contact{
  width: 75px;
  margin: 5px;
  border-radius: 100%;
}
.contact:hover{
  cursor: pointer;
  border: solid aliceblue 2px;
}

.info-container{
  /* background-color: rgba(255, 255, 255, 0.358); */
  padding: 50px;
  margin-top: -150px;
}

.about-div{
  margin: auto;
  max-width: 1080px;
}

.about-div p{
  line-height: 1.35;
  font-size: 18px;
}

.tech-container{
  margin: auto;
  max-width: 1080px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

/* .tech p{
  margin-top: px;
} */

.tech img{
  width: 69px;
  height: 69px;

}

.tech{
  display: flex;
  flex-direction: column;
  width: 120px;
  height: 120px;
  margin:15px;
  justify-content: center;
  align-items: center;
}

hr{
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
  margin: 30px 0px 30px 0px
}


.project-container{
  
  display: flex;
  flex-direction: column;
}

.all-proj{
  margin:auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1080px;
}
.each-img{
  width: 435px;
  border: 1px solid black;
  border-radius: 5px;
}

.each-project{
  border: solid black 1px;
  border-radius: 10px;
  padding: 20px;
  margin: 30px;
  max-width: 435px;
}

.each-project p{
  width: 435px;
}

.tech-used{
  font-style: italic;
}

.links a{
  margin-left: 10px;
  margin-right: 10px;
  color: black;
}

@media only screen and (max-width: 415px) {
  .contact{
    width: 50px;
    margin: 5px;
    border-radius: 100%;
  }
  .name{
    font-size: 53px;
  }
  .about-div{
    margin: auto;
    width: 345px;
  } 
  .each-img{
    width: 335px;
    border: 1px solid black;
    border-radius: 5px;

  }
  
  .each-project{
    border: solid black 1px;
    border-radius: 10px;
    padding: 2px;
    margin: 2px;
    max-width: 340px;
  }
  
  .each-project p{
    width: 340px;
  }

  .App {
    text-align: center;
    background-color: rgb(227, 227, 227);
    font-family: 'Montserrat', sans-serif;
    width: 100%;
  }
} 